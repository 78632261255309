@import '../../styles/variables';

.imageGrid{
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 13px;
  margin: 0 13px;

  @include from_tablet-portrait-up{
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
  }

  &__img{
    aspect-ratio: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
  }
  img{
    width: 120%;
    height: 120%;
  }

}

.popup{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,0.8);
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  z-index: 5;

  &__close{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  &__img{
    max-width: 85%;
    width: 85%;
    height: auto;

    & > img{
      width: 100%;
    }

    @include from-tablet-portrait-up{
      width: auto;
      height: 60%;
      top: 40%
    }
  }
}
