@import '../styles/variables';

.home {
  h2{
    font-size: 36px;
    text-transform: uppercase;
    font-weight: 300;

    @include from_tablet-portrait-up{
      font-size: 64px;
    }
  }

  h3{
    padding: 50px 0 20px;

    @include from-tablet-portrait-up{

    }
  }

  section{
    margin: 25px 0 45px 0;

    @include from_tablet-portrait-up{
      margin: 75px auto 85px auto;
      max-width: 1200px;
    }
  }

  &__text{
    padding: 0 15px;
    line-height: 1.4;
    max-width: 700px;
    margin: 0 auto;
  }

  &__mail{
    display: inline-block;
    margin-bottom: 33px;
    font-size: 18px;
  }
  &__insta{
    display: inline-block;
    width: 34px;
    height: 34px;
    color: $red;

    @include from_tablet-portrait-up{
      width: 45px;
      height: 45px;
    }

    &:hover{
      color: $white;
    }
  }

  &__copyright{
    font-size: 12px;
    padding-bottom: 20px;
  }
}

.splitter{
  max-height: 200px;
  margin: 0 auto;

  img{
    width: auto;
    height: auto;
  }
}

.imprint{
  max-width: 500px;
  margin: 0 auto;
  padding: 0 15px;
}
